.addResourceRemoveBtn{
    border:1.5px solid #C21807;
    padding:0.5em;
    border-radius:6px;  
    transition: background-color 0.3s ease;
    color:#C21807 ;
    font-weight: 600;
}

.addResourceRemoveBtn:hover{
    background:#c217073c ;
    cursor: pointer;
}

.addResourceMoveToBtn{
    border:1.5px solid  #50C878;
    color:#50C878;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.addResourceMoveToBtn:hover{
    background: #50c8785f;
    cursor: pointer;
}


.availableResourceTable{
    border: 1px solid gainsboro;
    border-radius: 6px;
    box-shadow: 2px 2px 20px gainsboro;
}
.availableResourceTableHead{
   font-weight:bolder ; 
}


.addedResourceTable{
    border: 1px solid rgba(0, 0, 0, 0.115);
    border-radius: 6px;
    box-shadow: 2px 2px 20px gainsboro;
    background: #ECF3F9;
}