/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .Container{
        display: block;
    }
    .OnboardManager{
        margin: 5px;
        padding: 5px;
    }
    .TaskManager{
        margin: 10px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .Container{
        display: block;
    }
    .OnboardManager{
        margin: 5px;
        padding: 5px;
    }
    .TaskManager{
        margin: 10px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .Container{
        display: block;
    }
    .OnboardManager{
        margin: 5px;
        padding: 5px;
    }
    .TaskManager{
        margin: 10px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .Container{
        display: flex;
    }
    .OnboardManager{
        width: 82%;
        margin: 5px;
    }
    .TaskManager{
        width: 18%;
        margin: 5px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .Container{
        display: flex;
    }
    .OnboardManager{
        width: 82%;
        margin: 10px;
    }
    .TaskManager{
        width: 18%;
        margin: 10px;
    }
}