body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: #281414;
  z-index: 10;
  margin: 0;
  padding: 0;
}

.search {
  display: flex;
  align-items: center;
  background: white;
  position: absolute;
  top: 1rem;
  left: 19%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10;
}

.search input {
  padding: 0.5rem;
  font-size: 1.5rem;
  width: 100%;
}

/* .locate {
  position: absolute;
  right: 20rem;
  background: none;
  border: none;
  z-index: 10;
} */
.locate img {
  width: 30px;
  cursor: pointer;
}

.contextMenu {
  position: fixed;
  width: 200px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 0;
  z-index: 99;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.contextMenuItem {
  background-color: #fff;
  width: 200px;
  font-size: 14px;
  top: calc(100% + 10px);
  padding: 10px 0;
  display: block;
  text-decoration: none;
  padding: 10px 15px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
}
.contextMenuItem :hover {
  background-color: #cccccc;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .locate{
    position: absolute;
    right: 20px;
    background: none;
    border: none;
    z-index: 10;
  }

  .MapOption{
    position:absolute;
    bottom:5%;
    right:62px;
    background:white;
    border-radius:5px;
  }

  .AccordianOption{
    zoom:0.5;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .locate{
    position: absolute;
    right: 20px;
    background: none;
    border: none;
    z-index: 10;
  }

  .MapOption{
    position:absolute;
    bottom:5%;
    right:62px;
    background:white;
    border-radius:5px;
  }

  .AccordianOption{
    zoom:0.5;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .locate{
    position: absolute;
    right: 20px;
    background: none;
    border: none;
    z-index: 10;
  }

  .MapOption{
    position:absolute;
    bottom:5%;
    right:9%;
    background:white;
    border-radius:5px;
  }

  .AccordianOption{
    zoom:0.8;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .locate{
    position: absolute;
    right: 20rem;
    background: none;
    border: none;
    z-index: 10;
  }

  .MapOption{
    position:absolute;
    bottom:5%;
    right:25%;
    background:white;
    border-radius:5px;
  }

  .AccordianOption{
    zoom:0.8;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .locate{
    position: absolute;
    right: 20rem;
    background: none;
    border: none;
    z-index: 10;
  }

  .MapOption{
    position:absolute;
    bottom:5%;
    right:24%;
    background:white;
  }

  .AccordianOption{
    zoom:0.8;
  }
}
