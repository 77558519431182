body {
    margin: 0px;
    font-family: 'Oswald', sans-serif;
}
  
.page {
    display: flex;
    /* min-height: 100vh; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
  
#form-file-upload {
    height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
}
  
#input-file-upload {
    display: none;
}
  
#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
}
  
.drag-active {
    background-color: #ffffff;
}
  
.upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
}
  
.upload-button:hover {
    text-decoration-line: underline;
}
  
#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.circle{
    position:absolute;
    top:-16px;
    left:16px;
    width:22px;
    height:22px;
    border-radius:18px;  
    background:red;
}

.count{
    color:#FFF;
    padding: 2.5px;
    margin: 0;
}