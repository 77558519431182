/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .alignItems{
        width: 100%;
        padding: 1% 1% 1% 10%;
        
    }
    .styling1{
        display: block;
       
    }
    .displaying{
        display: block;
    }
    .style{
        width: 100%;
        padding: 1%;
    }
    .inputStyle{
        width: 100%;
        margin: 10px 0px;
    }
    .details{
        display: block;
    }
    .owner{
        width: 100%;
        padding: 2%;
    }
    .dataSelector{
        width: 100%;
        margin:auto;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .alignItems{
        width: 100%;
        padding: 1% 1% 1% 15%;
        
    }
    .styling1{
        display: block;
       
    }
    .displaying{
        display: block;
    }
    .style{
        width: 100%;
        padding: 1%;
    }

    .inputStyle{
        width: 100%;
        margin: 10px 0px;
    }
    .details{
        display: block;
    }
    .owner{
        width: 100%;
        padding: 2%;
    }
    .dataSelector{
        width: 100%;
        margin:auto;
    }
    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .alignItems{
        width: 100%;
        padding: 1% 1% 1% 15%;
        
    }
    .styling1{
        display: block;
       
    }
    .displaying{
        display: block;
    }
    .style{
        width: 100%;
        padding: 1%;
    }

    .inputStyle{
        width: 100%;
        margin: 10px 0px;
    }
    .details{
        display: block;
    }
    .owner{
        width: 100%;
        padding: 2%;
    }
    .dataSelector{
        width: 100%;
        margin:auto;
    }
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .alignItems{
        width: 50%;
        padding: 1% 2% 1% 8%;
    }
    .styling1{
        display: flex;
       
    }
    .displaying{
        display: flex;
    }
    .style{
        width: 50%;
        padding: 1%;
    }
    .inputStyle{
        padding: 1%;
        width: 70% 
    }
    .details{
        display: flex;
    }
    .owner{
        width: 100%;
        padding: 0% 0% 0% 7%;
    }
    .dataSelector{
        width: 70%;
        margin:auto;
    }
    
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .alignItems{
        width: 50%;
        padding: 1% 2% 1% 7%;
    }
    .styling1{
        display: flex;
       
    }
    .displaying{
        display: flex;
    }
    .style{
        width: 50%;
        padding: 1%;
    }
    .inputStyle{
        padding: 1%;
        width: 70% 
    }
    .details{
        display: flex;
    }
    .owner{
        width: 100%;
        padding: 0% 0% 0% 7%;
    }

    .dataSelector{
        width: 70%;
        margin:auto;
    }

}