.footer {
  background-color: aqua;
}

#tool {
  background-color: #ffffff;
}

#ma {
  background-color: #f5f5f5;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .menit {
    display: none;
  }
  #specific {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
  .menit {
    display: none;
  }
  #specific {
    display: none;
  }
  #test1 {
    display: block;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .menit {
    display: none;
  }
  #specific {
    display: none;
  }
  #container {
    margin-right: 30%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #menubtn {
    display: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #menubtn {
    display: none;
  }
}
