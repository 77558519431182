.gray{
    background-color: #F2F2F2;
    width: 100%;
    text-align: start;
    font-size: small;
    padding-left: 0.5%;
}
.filter{
    background-color: white;
    margin-top: 2%;
    height: 55vh;
    width: 98%;
    overflow-y:auto ;
    overflow-x: hidden;
    border: solid 1px;
}
.properties{
    background-color: white;
    margin-top: 2%;
    border: solid 1px;
    height: 30vh;
    width: 98%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .HeadLogo{
        display: none;
    }    

    .HeadMenuContainer{
        background-color: white;
        display: flex;
        /* border: 1px solid gray; */
        align-items: center;
        padding: 5px;
        border-radius: 5px;
    }
}
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .HeadLogo{
        display: none;
    }

    .HeadMenuContainer{
        background-color: white;
        display: flex;
        /* border: 1px solid gray; */
        align-items: center;
        padding: 5px;
        border-radius: 5px;
    }
}
  
  /* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .HeadLogo{
        display: none;
    }

    .HeadMenuContainer{
        background-color: white;
        display: flex;
        /* border: 1px solid gray; */
        align-items: center;
        padding: 5px;
        border-radius: 5px;
    }
}
  
  /* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .HeadLogo{
        display: flex;
    }

    .HeadMenuContainer{
        padding:0 20px;
        display: flex;
        justify-content: space-between;
        width: 520
    }
}
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .HeadLogo{
        display: flex;
    }

    .HeadMenuContainer{
        padding:0 20px;
        display: flex;
        justify-content: space-between;
        width: 520
    }
}
  /* Changes in this file added button colour and background violet */