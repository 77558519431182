/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .stylePage {
    /* display: flex; */
    margin-top: 10%;
    height: 90vh;
  }

  .image {
    margin-top: 35%;
    margin-left: 0%;
    width: 0%;
    height: 0%;
  }

  .right {
    margin-top: 1%;
    margin-right: 20%;
    min-width: 50vh;
  }

  .paperStyle {
    display: block;
    width: 50vh;
    height: 75vh;
    margin: 2% 7% 7%;
    opacity: 0.8;
    position: absolute;
  }

  .textSyle {
    font-family: Georgia, "Times New Roman", Times, serif;
    width: 80%;
    position: absolute;
  }

  .Button {
    width: 60%;
    color: blueviolet;
    background-color: blueviolet;
  }

  .Container{
    display: block;
  }

  .SidePanelContainer{
    /* width: 100%; */
    margin-left: 5px;
    /* padding: 10px; */
  }

  .MainPanelContainer{
    width: 100%;
    margin: 5px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .stylePage {
    /* display: flex; */
    margin-top: 10%;
    height: 90vh;
  }

  .image {
    margin-top: 35%;
    margin-left: 0%;
    width: 0%;
    height: 0%;
  }

  .right {
    margin-top: 1%;
    margin-right: 20%;
    min-width: 50vh;
  }

  .paperStyle {
    display: block;
    width: 80vh;
    height: 75vh;
    margin: 2% 7% 7%;
    opacity: 0.8;
    position: absolute;
  }

  .textSyle {
    font-family: Georgia, "Times New Roman", Times, serif;
    width: 80%;
    position: absolute;
  }

  .Button {
    width: 60%;
    color: blueviolet;
    background-color: blueviolet;
  }

  .Container{
    display: block;
  }

  .SidePanelContainer{
    /* width: 100%; */
    margin-left: 5px;
    /* padding: 10px; */
  }

  .MainPanelContainer{
    width: 100%;
    margin: 5px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .stylePage {
    /* display: flex; */
    margin-top: 10%;
    height: 90vh;
  }

  .image {
    margin-top: 35%;
    margin-left: 0%;
    width: 0%;
    height: 0%;
  }

  .right {
    margin-top: 1%;
    margin-right: 20%;
    min-width: 50vh;
  }

  .paperStyle {
    display: block;
    width: 80vh;
    height: 75vh;
    margin: 2% 7% 7%;
    opacity: 0.8;
    position: absolute;
  }

  .textSyle {
    font-family: Georgia, "Times New Roman", Times, serif;
    width: 80%;
    position: absolute;
  }

  .Button {
    width: 60%;
    color: blueviolet;
    background-color: blueviolet;
  }

  .Container{
    display: block;
  }

  .SidePanelContainer{
    /* width: 100%; */
    margin-left: 5px;
    /* padding: 10px; */
  }

  .MainPanelContainer{
    width: 100%;
    margin: 5px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .stylePage {
    /* display: flex; */
    margin-top: 2%;
    height: 90vh;
  }

  .image {
    margin-top: 23%;
    margin-left: 1%;
    width: 80%;
    height: 50%;
  }

  .right {
    margin-top: 1%;
    margin-left: 5%;
    margin-right: 2%;
    min-width: 50vh;
  }

  .paperStyle {
    display: flex;
    width: 130vh;
    height: 80vh;
    margin: 2% 8% 8%;
    opacity: 0.8;
    position: absolute;
  }

  .textSyle {
    font-family: Georgia, "Times New Roman", Times, serif;
    width: 80%;
    position: absolute;
  }

  .Button {
    width: 60%;
    color: blueviolet;
    background-color: blueviolet;
  }

  .Container{
    display: flex;
  }

  .SidePanelContainer{
    width: 20%;
    margin: 5px;
    padding: 10px;
  }

  .MainPanelContainer{
    width: 80%;
    margin: 5px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .stylePage {
    /* display: flex; */
    margin-top: 2%;
    height: 90vh;
  }

  .image {
    margin-top: 15%;
    margin-left: 1%;
    width: 80%;
    height: 60%;
  }

  .right {
    margin-top: 1%;
    margin-left: 5%;
    margin-right: 2%;
  }

  .paperStyle {
    display: flex;
    width: 160vh;
    height: 80vh;
    margin: 2% 8% 8%;
    opacity: 0.8;
    position: absolute;
  }

  .textSyle {
    font-family: Georgia, "Times New Roman", Times, serif;
    width: 80%;
    position: absolute;
  }

  .Button {
    width: 50%;
    color: blueviolet;
    background-color: blueviolet;
  }

  .Container{
    display: flex;
  }

  .SidePanelContainer{
    width: 20%;
    margin: 5px;
    padding: 10px;
  }

  .MainPanelContainer{
    width: 80%;
    margin: 5px;
  }
}
/* Changes in this file added button colour and background violet */
