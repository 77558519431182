/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .style{
        display: block;
        width: 100%;
         
    }
    .alignment{
        text-align: center;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .style{
        display: block;
        width: 100%;
         
    }
    .alignment{
        text-align: center;
    }
        
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .style{
        display: block;
        width: 100%;
         
    }
    .alignment{
        text-align: center;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .style{
        display: flex;
        width: 100%;
    }
    .alignment{
        text-align: center;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .style{
    display: flex;
    width: 100%;
}
.alignment{
    text-align: center;
}
}