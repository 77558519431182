/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .AssetsDisplay{
        text-align: center;
        display: grid;
        grid: 130px / auto auto;
        grid-column-gap: 12px;
        grid-row-gap: 40px;
    }

    .AssignmentDisplay{
        display: block
    }

    .Options{
        zoom:0.8;
        padding:10;
        background:white;
        align-items: center;
    }

    .CardContent{
        justify-content: space-between;
        background: aliceblue;
        padding: 10px;
        border-radius: 5px;
        text-align: justify;
    }

    .AssignmentHeader{
        display:'block';
        text-align: justify;
        background: aliceblue;
        padding: 10px;
        border-radius: 5px
    }

    .AddSurveyorContainer{
        display: block;
        padding:10px
    }

    .InputField{
        margin: 5px;
    }

    .AssociateResourceStart{
        padding:10px;
    }

    .AssociatedAssestsContainer{
        display: block;
        padding: 5px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .AssetsDisplay{
        text-align: center;
        display: grid;
        grid: 130px / auto auto;
        grid-column-gap: 12px;
        grid-row-gap: 40px;
    }

    .AssignmentDisplay{
        display: block
    }

    .Options{
        zoom:0.8;
        padding:10;
        background:white;
        align-items: center
    }

    .CardContent{
        justify-content: space-between;
        background: aliceblue;
        padding: 10px;
        border-radius: 5px;
        text-align: justify;
    }

    .AssignmentHeader{
        display:'block';
        text-align: justify;
        background: aliceblue;
        padding: 10px;
        border-radius: 5px
    }

    .AddSurveyorContainer{
        display: block;
        padding:10px
    }

    .InputField{
        margin: 5px;
    }

    .AssociateResourceStart{
        padding:10px;
    }

    .AssociatedAssestsContainer{
        display: block;
        padding: 5px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .AssetsDisplay{
        text-align: center;
        display: grid;
        grid: 130px / auto auto;
        grid-column-gap: 12px;
        grid-row-gap: 40px;
    }

    .AssignmentDisplay{
        display: block
    }

    .Options{
        zoom:0.8;
        padding:10;
        background:white;
        align-items: center
    }

    .CardContent{
        justify-content: space-between;
        background: aliceblue;
        padding: 10px;
        border-radius: 5px;
        text-align: justify;
    }

    .AssignmentHeader{
        display:'block';
        text-align: justify;
        background: aliceblue;
        padding: 10px;
        border-radius: 5px
    }

    .AddSurveyorContainer{
        display: block;
        padding:10px
    }

    .InputField{
        margin: 5px;
    }

    .AssociateResourceStart{
        padding:10px;
    }

    .AssociatedAssestsContainer{
        display: block;
        padding: 5px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .AssetsDisplay{
        text-align: center;
        display: grid;
        grid: 150px / auto auto auto;
        grid-column-gap: 12px;
        grid-row-gap: 40px;
    }

    .AssignmentDisplay{
        display: flex;
        justify-content: space-between;
    }

    .Options{
        zoom:0.8;
        padding:10;
        display:flex;
        background:white;
        align-items: center
    }

    .CardContent{
        justify-content: space-between;
        background: aliceblue;
        padding: 10px;
        border-radius: 5px;
        display:flex;
    }

    .AssignmentHeader{
        display:flex;
        justify-content: space-between; 
        background: aliceblue;
        padding: 10px;
        border-radius: 5px
    }

    .AddSurveyorContainer{
        display:flex;
        justify-content:space-evenly;
        padding:10px
    }

    .InputField{
        width: 150px;
        margin: 5px;
    }

    .AssociateResourceStart{
        padding:10px;
        display:flex;
        justify-content:space-between
    }

    .AssociatedAssestsContainer{
        display: grid;
        grid-template-columns: auto;
        padding: 10px;
        justify-items:center;
        margin:20px
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .AssetsDisplay{
        text-align: center;
        display: grid;
        grid: 150px / auto auto auto auto;
        grid-column-gap: 12px;
        grid-row-gap: 40px;
    }

    .AssignmentDisplay{
        display: flex;
        justify-content: space-between;
    }
    
    .Options{
        zoom:0.8;
        padding:10;
        display:flex;
        background:white;
        align-items: center
    }

    .CardContent{
        justify-content: space-between;
        background: aliceblue;
        padding: 10px;
        border-radius: 5px;
        display:flex;
    }

    .AssignmentHeader{
        display:flex;
        justify-content: space-between; 
        background: aliceblue;
        padding: 10px;
        border-radius: 5px
    }

    .AddSurveyorContainer{
        display:flex;
        justify-content:space-evenly;
        padding:10px
    }
    
    .InputField{
        width: 150px;
        margin: 5px;
    }

    .AssociateResourceStart{
        padding:10px;
        display:flex;
        justify-content:space-between
    }

    .AssociatedAssestsContainer{
        display: grid;
        grid-template-columns: auto;
        padding: 10px;
        justify-items:center;
        margin:20px
    }
}
