/* .sidebarContainer {
  height: 22rem;
  width: 17.45rem;

  /* background-color: rgba(128, 128, 128, 0.22); */
/* }horizontal

.logoStyle {
  width: 114px;
  margin: 35px;
} */
.active {
  background-color: white;
  text-align: center;
  color: teal;
  border-top-width: 0;
  border-bottom: 2px solid teal;
}

.deActive {
  text-align: center;
  /* padding: 4%; */
  cursor: pointer;
  /* margin: 0px 20px; */
  color: black;
  background-color: white;
  /* border-radius: 20px;
  margin-top: 7px;  */
}

.TopHeader{
  background: #5F9EA0;
  padding: 10px;
  color: white;
  margin: 10px 0px;
}

@media only screen and (max-width: 600px) {
  .Container{
    background:#f2f2f2;
    height:75vh;
    overflow:auto;
    padding:5px
  }
}

@media only screen and (min-width: 600px) {
  .Container{
    background:#f2f2f2;
    height:75vh;
    overflow:auto;
    padding:5px
  }
}

@media only screen and (min-width: 768px) {
  .Container{
    background:#f2f2f2;
    height:75vh;
    overflow:auto;
    padding:5px
  }
}

@media only screen and (min-width: 992px) {
  .Container{
    background:#f2f2f2;
    height:75vh;
    overflow:auto;
    box-shadow:0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%) 0px 1px 3px 0px rgb(0 0 0 / 12%);
    padding:5px
  }
}

@media only screen and (min-width: 1200px) {
  .Container{
    background:#f2f2f2;
    height:75vh;
    overflow:auto;
    box-shadow:0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%) 0px 1px 3px 0px rgb(0 0 0 / 12%);
    padding:5px
  }
  
}