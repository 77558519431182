.ActiveMenu{
    padding: 1px;
    background: white;
    color: teal;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;
}
.DeactiveMenu{
    padding: 1px;
    background: teal;
    color: white;
    border-radius: 5px;
    border:1px solid white;
    margin: 5px;
    cursor: pointer;
}