/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .Container{
        display: block;
    }

    .LeftPanel{
        margin: 10px;
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
        border-radius:5px;
        background: rgb(83, 69, 154);
        color: white
    }

    .DetailedProfile{
        margin: 10px;
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
        border-radius:5px;
        /* height:40vh; */
        color:#53459A;
        overflow:auto
    }

    .LeftPanel1{
        margin: 10px;
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
        border-radius:5px;
        overflow:auto; 
        padding:10px
    }

    .LeftPanelLowerContainer{
        color: rgb(83, 69, 154);
    }
    
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .Container{
        display: block;
    }

    .LeftPanel{
        margin: 10px;
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
        border-radius:5px;
        background: rgb(83, 69, 154);
        color: white
    }

    .DetailedProfile{
        margin: 10px;
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
        border-radius:5px;
        /* height:40vh; */
        color:#53459A;
        overflow:auto
    }

    .LeftPanel1{
        margin: 10px;
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
        border-radius:5px;
        overflow:auto; 
        padding:10px
    }

    .LeftPanelLowerContainer{
        color: rgb(83, 69, 154);
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .Container{
        display: block;
    }

    .LeftPanel{
        margin: 10px;
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
        border-radius:5px;
        background: rgb(83, 69, 154);
        color: white
    }

    .DetailedProfile{
        margin: 10px;
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
        border-radius:5px;
        /* height:40vh; */
        color:#53459A;
        overflow:auto
    }

    .LeftPanel1{
        margin: 10px;
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
        border-radius:5px;
        overflow:auto; 
        padding:10px
    }

    .LeftPanelLowerContainer{
        color: rgb(83, 69, 154);
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .Container{
        display: flex;
    }

    .LeftPanel{
        margin: 1%;
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
        border-radius:5px;
        width: 25%;
        height:87vh;
        background: rgb(83, 69, 154);
        color: white
    }

    .DetailedProfile{
        margin: 1%;
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
        border-radius:5px;
        width: 30%;
        height:40vh;
        color:#53459A;
        overflow:auto
    }

    .LeftPanel1{
        margin: '1%';
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
        border-radius:5px;
        height:45vh;
        overflow:auto; 
        padding:10px
    }

    .LeftPanelLowerContainer{
        width: 75%;
        color: rgb(83, 69, 154);
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .Container{
        display: flex;
    }

    .LeftPanel{
        margin: 1%;
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
        border-radius:5px;
        width: 25%;
        height:87vh;
        background: rgb(83, 69, 154);
        color: white
    }

    .DetailedProfile{
        margin: 1%;
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
        border-radius:5px;
        width: 30%;
        height:40vh;
        color:#53459A;
        overflow:auto
    }

    .LeftPanel1{
        margin: '1%';
        box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
        border-radius:5px;
        height:45vh;
        overflow:auto; 
        padding:10px
    }

    .LeftPanelLowerContainer{
        width: 75%;
        color: rgb(83, 69, 154);
    }
}