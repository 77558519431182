.MuiTable-root {
    border-collapse: collapse !important;
}

.MuiTableCell-root {
    border: 1px solid black !important;
}
.tableRoot {
    border-collapse: collapse;
}

.tableCell {
    border: 1px solid black !important;
}
