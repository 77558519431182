/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .style {
    width: 100%;
    display: block;
  }
  .alignItems {
    width: 100%;
    padding: 1% 2% 1% 2%;
  }
  .CheckBox {
    margin-left: 70%;
  }
  .ItemStyle {
    margin-top: -12%;
    display: block;
  }
  /* .style1 {
    display: none;
  } */
  .UploadStyle {
    display: block;
  }
  .UploaderStyle {
    width: 80%;
  }
  .PaperStyle {
    height: 620;
    margin: 10;
    overflow: auto;
  }

  .TitleStyle {
    text-align: center;
    padding: 20px 10px;
    justify-content: center;
    margin: 0px auto;
    font-weight: bold;
    font-size: 24px;
  }
  .TitleLineStyle {
    width: 50%;
  }
  .FileUploadText {
    text-align: left;
    width: 60%;
  }
  .UploadBtnPlacement {
    padding: 2% 20% 1% 0%;
    width: 40%;
  }
  .UploadBtn {
    border-radius: 18px;
    /* justify-content: right; */
    margin-left: 0%;
  }

  .SubmitBtn {
    border-radius: 18px;
    /* justify-content: right; */
    margin-left: 0%;
    margin-top: 10%;
  }
  .SubmitBtnPlacement {
    text-align: center;
    padding: 2% 5% 2% 0%;
  }
  .UploadDocContainer{
    display: block;
  }

  .UploadDocIndividualContainer{
    display:block;
  }
  .UploadDocIndividualChild{
    margin: 0.5%;
    overflow:auto;
    color:#53459A;
    text-align: center;
  }
}

@media only screen and (min-width: 460px) {
  .style {
    width: 100%;
    display: block;
  }
  .alignItems {
    width: 100%;
    padding: 1% 2% 1% 2%;
  }
  .CheckBox {
    margin-left: 5%;
  }
  .ItemStyle {
    display: flex;
    margin-top: 0%;
  }
  /* .style1 {
    display: none;
  } */
  .UploadStyle {
    display: flex;
  }
  .UploaderStyle {
    width: 80%;
  }
  .PaperStyle {
    height: 620;
    margin: 10;
    overflow: auto;
  }

  .TitleStyle {
    text-align: center;
    padding: 20px 10px;
    justify-content: center;
    margin: 0px auto;
    font-weight: bold;
    font-size: 24px;
  }
  .TitleLineStyle {
    width: 50%;
  }
  .FileUploadText {
    text-align: left;
    width: 60%;
  }
  .UploadBtnPlacement {
    padding: 2% 20% 1% 0%;
    width: 40%;
  }
  .UploadBtn {
    border-radius: 18px;
    /* justify-content: right; */
    margin-left: 0%;
  }

  .SubmitBtn {
    border-radius: 18px;
    /* justify-content: right; */
    margin-left: 0%;
  }
  .SubmitBtnPlacement {
    text-align: center;
    padding: 2% 5% 2% 0%;
  }

  .UploadDocContainer{
    display: block;
  }

  .UploadDocIndividualChild{
    margin: 0.5%;
    overflow:auto;
    color:#53459A;
    text-align: center;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .style {
    width: 100%;
    display: block;
  }
  .alignItems {
    width: 100%;
    padding: 1% 2% 1% 2%;
  }
  .ItemStyle {
    display: flex;
  }
  /* .style1 {
    display: none;
  } */
  .UploadStyle {
    display: flex;
  }
  .UploaderStyle {
    width: 80%;
  }
  .PaperStyle {
    height: 620;
    margin: 10;
    overflow: auto;
  }

  .TitleStyle {
    text-align: center;
    padding: 20px 10px;
    justify-content: center;
    margin: 0px auto;
    font-weight: bold;
    font-size: 24px;
  }
  .TitleLineStyle {
    width: 50%;
  }
  .FileUploadText {
    text-align: left;
    width: 60%;
  }
  .UploadBtnPlacement {
    padding: 2% 20% 1% 0%;
    width: 40%;
  }
  .UploadBtn {
    border-radius: 18px;
    /* justify-content: right; */
    margin-left: 0%;
  }

  .SubmitBtn {
    border-radius: 18px;
    /* justify-content: right; */
    margin-left: 0%;
  }
  .SubmitBtnPlacement {
    text-align: center;
    padding: 2% 5% 2% 0%;
  }

  .UploadDocContainer{
    display: block;
  }

  .UploadDocIndividualChild{
    margin: 0.5%;
    overflow:auto;
    color:#53459A;
    text-align: center;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .style {
    width: 100%;
    display: block;
  }
  .alignItems {
    width: 100%;
    padding: 1% 2% 1% 2%;
  }
  .ItemStyle {
    display: flex;
  }
  /* .style1 {
    display: none;
  } */
  .UploadStyle {
    display: flex;
  }
  .UploaderStyle {
    width: 80%;
  }
  .PaperStyle {
    height: 620;
    margin: 10;
    overflow: auto;
  }

  .TitleStyle {
    text-align: center;
    padding: 20px 10px;
    justify-content: center;
    margin: 0px auto;
    font-weight: bold;
    font-size: 24px;
  }
  .TitleLineStyle {
    width: 50%;
  }
  .FileUploadText {
    text-align: left;
    width: 60%;
  }
  .UploadBtnPlacement {
    padding: 2% 20% 1% 0%;
    width: 40%;
  }
  .UploadBtn {
    border-radius: 18px;
    /* justify-content: right; */
    margin-left: 0%;
  }

  .SubmitBtn {
    border-radius: 18px;
    /* justify-content: right; */
    margin-left: 0%;
  }
  .SubmitBtnPlacement {
    text-align: center;
    padding: 2% 5% 2% 0%;
  }

  .UploadDocContainer{
    display: block;
  }

  .UploadDocIndividualChild{
    margin: 0.5%;
    overflow:auto;
    color:#53459A;
    text-align: center;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .style {
    width: 100%;
    display: flex;
    position: inherit;
  }
  .alignItems {
    width: 50%;
    padding: 1% 2% 1% 2%;
  }
  .CheckBox {
    margin-left: 0%;
  }
  .ItemStyle {
    margin-top: 0%;
    display: flex;
  }
  /* .style1 {
    display: none;
  } */
  .UploadStyle {
    display: flex;
  }
  .UploaderStyle {
    width: 80%;
  }
  .PaperStyle {
    height: 620;
    margin: 10;
    overflow: auto;
  }

  .TitleStyle {
    text-align: center;
    padding: 20px 10px;
    justify-content: center;
    margin: 0px auto;
    font-weight: bold;
    font-size: 24px;
  }
  .TitleLineStyle {
    width: 50%;
  }
  .FileUploadText {
    text-align: left;
    width: 60%;
  }
  .UploadBtnPlacement {
    padding: 2% 20% 1% 0%;
    width: 40%;
  }
  .UploadBtn {
    border-radius: 18px;
    /* justify-content: right; */
    margin-left: 0%;
  }

  .SubmitBtn {
    border-radius: 18px;
    /* justify-content: right; */
    margin-left: 0%;
  }
  .SubmitBtnPlacement {
    text-align: center;
    padding: 2% 5% 2% 0%;
  }

  .UploadDocContainer{
    display: flex;
  }

  .UploadDocIndividualChild{
    margin: 0.5%;
    width: 49%;
    overflow:auto;
    color:#53459A;
    text-align: center;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .style {
    width: 100%;
    display: flex;
  }
  .alignItems {
    width: 50%;
    padding: 1% 2% 1% 2%;
  }
  .CheckBox {
    margin-left: 0%;
  }
  .ItemStyle {
    margin-top: 0%;
    display: flex;
  }
  /* .style1 {
    display: none;
  } */
  .UploadStyle {
    display: flex;
  }
  .UploaderStyle {
    width: 80%;
  }
  .PaperStyle {
    height: 620;
    margin: 10;
    overflow: auto;
  }

  .TitleStyle {
    text-align: center;
    padding: 20px 10px;
    justify-content: center;
    margin: 0px auto;
    font-weight: bold;
    font-size: 24px;
  }
  .TitleLineStyle {
    width: 50%;
  }
  .FileUploadText {
    text-align: left;
    width: 60%;
  }
  .UploadBtnPlacement {
    padding: 2% 20% 1% 0%;
    width: 40%;
  }
  .UploadBtn {
    border-radius: 18px;
    /* justify-content: right; */
    margin-left: 0%;
  }

  .SubmitBtn {
    border-radius: 18px;
    /* justify-content: right; */
    margin-left: 0%;
  }
  .SubmitBtnPlacement {
    text-align: center;
    padding: 2% 5% 2% 0%;
  }
  .UploadDocContainer{
    display: flex;
  }
  .UploadDocIndividualChild{
    margin: 0.5%;
    width: 49%;
    overflow:auto;
    color:#53459A;
    text-align: center;
  }
}
