body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  h1 {
    position: absolute;
    top: 1rem;
    left: 1rem;
    color: #281414;
    z-index: 10;
    margin: 0;
    padding: 0;
  }
  
  .search {
    display: flex;
    align-items: center;
    background: white;
    position: absolute;
    top: 1rem;
    left: 19%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 400px;
    z-index: 10;
  }
  
  .search input {
    padding: 0.5rem;
    font-size: 1.5rem;
    width: 100%;
  }
  
  .locate {
    position: absolute;
    top: 5rem;
    right: 1rem;
    background: none;
    border: none;
    z-index: 10;
  }
  .locate img {
    width: 30px;
    cursor: pointer;
  }
  
  .contextMenu {
    position: fixed;
    width: 200px;
    left: 0;
    top: calc(100% + 10px);
    border-radius: 4px;
    background-color: #fff;
    padding: 10px 0;
    z-index: 99;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  
  .contextMenuItem {
    background-color: #fff;
    width: 200px;
    font-size: 14px;
    top: calc(100% + 10px);
    padding: 10px 0;
    display: block;
    text-decoration: none;
    padding: 10px 15px;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;
    position: relative;
    margin-bottom: 2px;
    font-weight: 500;
    display: flex;
    align-items: center;
    outline: none;
  }
  .contextMenuItem :hover {
    background-color: #cccccc;
  }
  